import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    --webkit-font-smoothing: antialiased;
  }

  html {
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.dark};
    font-size: 62.5%;
    font-family: ${({ theme }) => theme.font.family.sans};
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.2;
    overflow: scroll;
  }

  nav {
    > .active {
      opacity: .5;
    }
  }

  .no-overflow {
    overflow: hidden;
  }

  .container {
    max-width: 100%;
    margin: 0 auto;
    @media all and (min-width: 576px) {
      max-width: 95vw;
    }
    @media all and (min-width: 992px) {
      max-width: 90vw;
    }
    @media all and (min-width: 1200px) {
      max-width: 85vw;
    }
    @media all and (min-width: 1400px) {
      max-width: 80vw;
    }
  }

`

export default GlobalStyle

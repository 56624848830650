import React from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "assets/styles/theme"
import GlobalStyle from "assets/styles/global-style"
import SEO from "components/SEO/SEO"

const MainTemplate = ({ children }) => (
  <>
    <SEO />
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  </>
)

export default MainTemplate

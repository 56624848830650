import React from "react"
import { Helmet } from "react-helmet"

const SEO = () => (
  <Helmet>
    <html lang="en" />
    <title>Portfolio | Adrian Krasnodębski</title>

    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link href="https://fonts.cdnfonts.com/css/hk-groteks" rel="stylesheet" />
    <link
      href="https://fonts.googleapis.com/css2?family=Fraunces:ital,wght@1,100;1,300;1,400;0,600;"
      rel="stylesheet"
    />
  </Helmet>
)

export default SEO

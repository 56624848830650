export const theme = {
  colors: {
    white: "#FCFBFC",
    dark: "#444444",
    lightDark: "#c4c4c4",
  },
  font: {
    size: {
      xs: ".9rem",
      s: "1.4rem",
      r: "1.8rem",
      m: "2.6rem",
      l: "4.2rem",
      xl: "7.2rem",
      xxl: "9.4rem",
    },
    weight: {
      thin: "100",
      light: "300",
      regular: "400",
      bold: "700",
    },
    family: {
      sans: '"HK Grotesk", sans-serif',
      serif: "Fraunces, serif",
    },
  },
}
